export enum COMMON_TEST_IDS {
    TA_RATING_CONTAINER = 'taRatingContainer',
    NAME = 'NAME',
    STAR_RATING = 'starRating',
    SUB_HEADING = 'subHeading',
    TA_RATING_CONTAINER_2 = 'taRatingContainer2',
    REVIEW_RATINGS = 'reviewRatings',
    PRICE = 'PRICE',
    TAX_TEXT = 'taxText',
    PRICE_FREE_TEXT = 'priceFreeText',
    FREE_CANCELLATION = 'freeCancellation',
    FREE_BREAKFAST = 'freeBreakfast',
    LOCALITY_SEARCH_FIELD = 'LOCALITY_SEARCH_FIELD',
    BACK_CTA = 'BACK_CTA',
    SHARE_CTA = 'SHARE_CTA',
    SHOW_MORE = 'SHOW_MORE',
    HEADING = 'HEADING',
    CLEAR_CTA = 'CLEAR_CTA',
    MIN = 'MIN',
    MAX = 'MAX',
    SEARCH_FIELD = 'SEARCH_FIELD',
    CITY = 'CITY',
    DATE = 'DATE',
    PAX = 'PAX',
    SLASHED_PRICE = 'SLASHED_PRICE',
    DISCOUNT_TEXT = 'DISCOUNT_TEXT',
    OFFER_FREE_TEXT = 'OFFER_FREE_TEXT',
    FREE_CANCELLATION_AND_FREE_BREAKFAST = 'FREE_CANCELLATION_AND_FREE_BREAKFAST',
    SOLD_OUT = 'SOLD_OUT',
    PROMOTION_CALLOUT = 'PROMOTION_CALLOUT',
    IMAGE = 'IMAGE',
    INCLUSION = 'INCLUSION',
    PROMOTION_TEXT = 'PROMOTION_TEXT',
    PRICE_LABEL = 'PRICE_LABEL',
    PRICE_TEXT = 'PRICE_TEXT',
    TITLE = 'TITLE',
    SUB_TITLE = 'SUB_TITLE',
    flight_COUNT_FREE_TEXT = 'flight_COUNT_FREE_TEXT',
    flight_COUNT = 'flight_COUNT',
    ALL_FILTERS = 'ALL_FILTERS',
    OPTION = 'OPTION',
    CTA = 'CTA',
    CHECK_IN = 'CHECK_IN',
    CHECK_OUT = 'CHECK_OUT',
    SEARCH = 'SEARCH',
    SUGGESTION = 'SUGGESTION',
    CHILD_SUGGESTION = 'CHILD_SUGGESTION',
    RECENT_SEARCH = 'RECENT_SEARCH',
    SUGGESTIONS = 'suggestions',
    RECENTLY_VIEWED = 'recentlyViewed',
    POPULAR_DESTINATION = 'popularDestinations',
    DECREMENT = 'decrement',
    INCREMENT = 'increment',
    ROOM = 'room',
    FROM_DATE = 'fromDate',
    TO_DATE = 'toDate',
    ADULT = 'adult',
    CHILD = 'child'
}

export enum HOME_PAGE_COMPONENTS_NAME {
    SEARCH_FIELD = 'searchField',
    SEARCH_BUTTON = 'searchButton',
    TOP_DESTINATION = 'popularDestination',
    TOP_flightS = 'topflights',
    TRENDING_GATEWAYS = 'trendingGateways',
    BSB_BANNER = 'bsbBanner',
    AUTO_SUGGEST_OPTION = 'autoSuggestOption',
    LOGIN_BUTTON = 'loginButton',
    PAX_OPTION = 'paxOption',
    PAX_ADD_MORE = 'paxAddMoreRoom',
    PAX_SHOW_OPTION = 'paxShowOption',
    PAX_ADD_ANOTHER_ROOM = 'paxAddAnotherRoom',
    PAX_BUTTON = 'paxButton',
    PAX_COUNTER = 'paxCounter',
    PAX_REMOVE_ROOM = 'paxRemoveRoom',
    PAX_PERSON_COUNT = 'paxPersonCount',
    CALENDAR = 'calendar',
    MY_ACCOUNT = 'myAccount',
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
    CFNR_CARDS = 'CFNR_CARDS',
    ROOM_SELECTION_FILTER = 'ROOM_SELECTION_FILTER'
}

export const TEST_IDS = { ...HOME_PAGE_COMPONENTS_NAME, ...COMMON_TEST_IDS }
