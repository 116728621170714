import Clevertap from '@/analytics/clevertap'
import { getPageName } from '@/utils/actions'
import { getCookie } from '@/utils/general/cookie'

interface propType {
    initialLoadTime: number
    getAbTest?: any
    abhome?: string
    abWallet?: string
}

export const airUiAction = (
    { action_name, ...data }: { action_name: string; [key: string]: unknown },
    pageName?: string,
    actionName = 'Air_UI_Action'
) => {
    Clevertap.event(actionName, {
        action_name,
        bento_flag: 'yes',
        page_name: pageName || getPageName() || '',
        ...data
    })
}

export const sendClevertapUIActionEvent = ({
    actionName = '',
    actionType = 'BUTTON',
    page = '',
    ...additionalData
}) => {
    const fullPayload = {
        action_name: actionName,
        action_type: actionType,
        source: 'web',
        domain: window.location.host,
        source_page_name: page,
        ...additionalData
    }

    Clevertap.event('Air_UI_Action', fullPayload)
}

export const homePageLoad = ({ initialLoadTime, getAbTest = {}, abhome = '', abWallet = '' }: propType) => {
    Clevertap.event('Air_PageLoad', {
        page_name: 'flights_home',
        utm_source:
            location?.search?.split?.('utm_source=').length > 1
                ? location?.search?.split?.('utm_source=')?.pop()?.split('&')?.[0]
                : getCookie('utm_source') || 'organic',
        is_Bento_User: 'yes',
        last_event_type: -1,
        last_page_name: -1,
        page_load_time: new Date().getTime() - initialLoadTime || 'N/A',
        tk_home_abcookie: abhome,
        wallet_display_abcookie: abWallet
    })
}
