// TODO(@): Shift to @cleartrip/ct-design

import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'

import { normalizeSize } from '@/utils/general/uiHelper'

import './Stencil.scss'

interface IStencilProps {
    /**
     * Additional classes to be added to the component
     */
    className?: string
    /**
     * Height of the stencil
     */
    height: string | number
    /**
     * Width of the stencil
     */
    width: string | number
    /**
     * Either a block or inline
     */
    type?: string
    /**
     * Adds a shimmer to the placeholders
     */
    shimmer?: boolean
    /**
     * Invert colors of stencil for dark backgrounds
     */
    dark?: boolean
}

const Stencil = (props: PropsWithChildren<IStencilProps>) => {
    const styles = {
        width: normalizeSize(props.width),
        height: normalizeSize(props.height)
    }
    const classes = classNames(props.className, {
        Stencil: true,
        'has-shimmer': props.shimmer,
        'is-rounded': props.type === 'rounded',
        'is-circular': props.type === 'circular',
        'is-dark': props.dark === true
    })

    return (
        <div className={classes} style={styles}>
            {props.children}
        </div>
    )
}

Stencil.defaultProps = {
    children: null,
    className: '',
    height: '100',
    width: '100',
    type: 'block',
    shimmer: false,
    dark: false
}

export { Stencil }
