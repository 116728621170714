import { isServer } from '@/utils'
import { IClevertapReact } from './type'
import { CLEVERTAP_SCRIPT_HTTP, CLEVERTAP_SCRIPT_HTTPS } from '@/constants/analytics'

const ClevertapReact: IClevertapReact = {
    initialize(accountId) {
        if (!isServer() && !window.clevertap?.event) {
            window.clevertap = {
                event: [],
                profile: [],
                account: [],
                onUserLogin: [],
                notifications: [],
                logout: () => {
                    return
                }
            }
            window.clevertap?.account?.push({ id: accountId })
            ;(function () {
                const wzrk = document.createElement('script')
                wzrk.type = 'text/javascript'
                wzrk.async = true
                wzrk.src = `${document.location.protocol == 'https:' ? CLEVERTAP_SCRIPT_HTTPS : CLEVERTAP_SCRIPT_HTTP}`
                const s = document.getElementsByTagName('script')[0]
                s.parentNode?.insertBefore(wzrk, s)
            })()
        }
    },

    event(name, payload) {
        if (payload) {
            window.clevertap?.event?.push(name, payload)
        } else {
            window.clevertap?.event?.push(name)
        }
    },

    profile(payload) {
        window.clevertap?.profile?.push(payload)
    },

    logout() {
        window.clevertap?.logout()
    }
}

export default ClevertapReact
