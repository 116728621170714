export function TripAdvisor({ height = 16, width = 25 }: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
            <path
                fill="#1A1A1A"
                d="M22.956 4.66 25 2.445h-4.53a14.184 14.184 0 0 0-15.924 0H.004l2.043 2.217a6.227 6.227 0 0 0-2.04 4.328 6.208 6.208 0 0 0 1.64 4.493 6.244 6.244 0 0 0 4.355 2.008 6.265 6.265 0 0 0 4.498-1.663L12.502 16l2.001-2.17a6.23 6.23 0 0 0 4.243 1.658c3.449 0 6.247-2.787 6.247-6.225a6.193 6.193 0 0 0-2.037-4.602Zm-16.7 8.814a4.236 4.236 0 0 1-2.349-.71 4.2 4.2 0 0 1-.64-6.482 4.23 4.23 0 0 1 4.606-.913A4.224 4.224 0 0 1 9.77 6.921a4.203 4.203 0 0 1-.526 5.32 4.227 4.227 0 0 1-2.988 1.233Zm6.246-4.334c0-2.772-2.024-5.151-4.69-6.17a12.231 12.231 0 0 1 9.38 0c-2.668 1.02-4.69 3.398-4.69 6.17Zm6.244 4.334a4.236 4.236 0 0 1-2.348-.71 4.2 4.2 0 0 1-.64-6.482 4.23 4.23 0 0 1 4.605-.913 4.224 4.224 0 0 1 1.897 1.552 4.203 4.203 0 0 1-.526 5.32 4.234 4.234 0 0 1-2.988 1.233Zm0-6.421a2.22 2.22 0 0 0-2.047 1.363 2.203 2.203 0 0 0 .48 2.407 2.218 2.218 0 0 0 3.782-1.562c0-.585-.233-1.147-.649-1.561a2.22 2.22 0 0 0-1.566-.647ZM8.47 9.26a2.203 2.203 0 0 1-1.368 2.04 2.222 2.222 0 0 1-2.414-.478 2.207 2.207 0 0 1 1.566-3.77 2.221 2.221 0 0 1 2.048 1.363c.112.268.17.555.17.845h-.002Z"
            />
        </svg>
    )
}
