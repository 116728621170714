import { isUserSignedIn } from '@/utils/general/user'
import { getCookie } from '@/utils/general/cookie'
import { isEmpty, pageLoadTime, getURLSearchParam } from '@/utils/general/browserHelper'
import { getNestedField } from '@/utils'

import ClevertapReact from './clevertap'

class Clevertap {
    enabled: boolean
    constructor() {
        this.enabled = false
        if (process.env.NEXT_PUBLIC_CLEVERTAP_KEY) {
            this.enabled = true
            ClevertapReact.initialize(process.env.NEXT_PUBLIC_CLEVERTAP_KEY)
        }
    }

    event = (name: string, payload: Record<string, unknown> = {}) => {
        try {
            if (this.enabled) {
                const ctAb = isEmpty(getCookie('ct-ab')) ? {} : JSON.parse(decodeURIComponent(getCookie('ct-ab')))

                const domain = getNestedField(['location', 'hostname'], window)
                const utmSource = getURLSearchParam('utm_source')

                payload.platform = 'Desktop'
                payload.domainName = domain || 'NA'
                payload.is_logged_in = isUserSignedIn() ? 'yes' : 'no'
                payload.testGlobal_abcookie = ctAb['testGlobal'] || 'N/A'
                payload.RNIFlowType_abcookie = ctAb['RNIFlowType'] || 'N/A'
                payload.login_banner_cookie = ctAb['login_init'] || 'N/A'
                payload.h_exp_dom_abcookie = ctAb['h_exp_dom'] || 'N/A'
                payload.h_exp_intl_abcookie = ctAb['h_exp_intl'] || 'N/A'
                payload.pageLoadTime = pageLoadTime()
                payload.utm_source = utmSource || 'N/A'
                setTimeout(() => {
                    ClevertapReact.event(name, payload)
                }, 300)
            }
        } catch (e) {
            console.error(e)
        }
    }

    profile = (payload: any) => {
        if (this.enabled) {
            ClevertapReact.profile(payload)
        }
    }

    logout = () => {
        if (this.enabled) {
            ClevertapReact.logout()
        }
    }
}

const ClevertapObject = new Clevertap()
export default ClevertapObject
