import { getCurrencyByLocale, readParam } from '@/utils/general/browserHelper'
import { getUserAuthValues, isUserSignedIn } from '@/utils/general/user'

declare global {
    interface Window {
        dataLayer?: any[]
    }
}
const emptyValue = 'N/A'

export const dataLayerGA = (payload: any, event: string) => {
    const dataLayer: any[] = window.dataLayer ?? []
    const { userId } = getUserAuthValues()
    let finalPayload = {
        event: event,
        platform: 'website',
        user_status: isUserSignedIn() ? 'LoggedIn' : 'LoggedOut',
        ...payload
    }
    if (window.location.pathname == '/flights/amendments/search-results') {
        finalPayload = {
            ...finalPayload,
            isAmendment: true
        }
    }
    if (userId) {
        finalPayload.user_id = userId
    }
    dataLayer.push(finalPayload)
}

export function GaDataPush(type: string, eventName: string, payload: any) {
    const dataLayer: any[] = window.dataLayer ?? []
    const { userId } = getUserAuthValues()

    const updatedPayload = {
        ...payload,
        platform: 'website',
        user_status: isUserSignedIn() ? 'LoggedIn' : 'LoggedOut'
    }

    if (userId) {
        updatedPayload.user_id = userId
    }

    dataLayer.push([type, eventName, updatedPayload])
}


export function getInitialDataLayerParams(page) {
    try {
        const { country, currency } = getCurrencyByLocale()
        const utmDefault = emptyValue

        let baseObj = {
            Domain: window.location.host,
            Country: country,
            Language: 'EN',
            'Device-Type': 'Desktop'
        }
        if (page === 'home') {
            return baseObj
        }
        baseObj.Product = 'Air'
        if (page === 'flights') {
            return baseObj
        }
        if (page === 'traveller') {
            baseObj['Payment-Type'] = emptyValue
        }
        readParam('utm_source', { expiry: 30 })
        readParam('utm_campaign', { expiry: 30 })
        readParam('utm_medium', { expiry: 30 })
        readParam('utm_term', { expiry: 30 })
        readParam('utm_uid', { expiry: 30 })
        baseObj = {
            ...baseObj,
            'Currency': currency,
            'Source-Affiliatefirstsource':
                readParam('Source-Affiliatefirstsource', {
                    alias: 'utm_source',
                    expiry: 1
                }) || utmDefault,
            'Campaign-Affiliatefirstsource':
                readParam('Campaign-Affiliatefirstsource', {
                    alias: 'utm_campaign',
                    expiry: 1
                }) || utmDefault,
            'Medium-Affiliatefirstsource':
                readParam('Medium-Affiliatefirstsource', {
                    alias: 'utm_medium',
                    expiry: 1
                }) || utmDefault,
            'Term-Affiliatefirstsource':
                readParam('Term-Affiliatefirstsource', {
                    alias: 'utm_term',
                    expiry: 1
                }) || utmDefault,
            'Uid-Affiliatefirstsource':
                readParam('Uid-Affiliatefirstsource', {
                    alias: 'utm_uid',
                    expiry: 1
                }) || utmDefault,
            'Source-Metafirstsource':
                readParam('Source-Metafirstsource', {
                    alias: 'utm_source',
                    expiry: 30
                }) || utmDefault
        }
        return baseObj
    } catch (e) {
        // logError(e)
        console.log(e);
        
        return {}
    }
}